// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bkad-js": () => import("./../../../src/pages/bkad.js" /* webpackChunkName: "component---src-pages-bkad-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-aristotel-js": () => import("./../../../src/pages/products/aristotel.js" /* webpackChunkName: "component---src-pages-products-aristotel-js" */),
  "component---src-pages-products-atlas-js": () => import("./../../../src/pages/products/atlas.js" /* webpackChunkName: "component---src-pages-products-atlas-js" */),
  "component---src-pages-products-cleverpark-js": () => import("./../../../src/pages/products/cleverpark.js" /* webpackChunkName: "component---src-pages-products-cleverpark-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-products-myboostr-js": () => import("./../../../src/pages/products/myboostr.js" /* webpackChunkName: "component---src-pages-products-myboostr-js" */),
  "component---src-pages-products-parkomat-js": () => import("./../../../src/pages/products/parkomat.js" /* webpackChunkName: "component---src-pages-products-parkomat-js" */),
  "component---src-pages-products-sharptoll-js": () => import("./../../../src/pages/products/sharptoll.js" /* webpackChunkName: "component---src-pages-products-sharptoll-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-services-ekspertnaya-ocenka-js": () => import("./../../../src/pages/services/ekspertnaya_ocenka.js" /* webpackChunkName: "component---src-pages-services-ekspertnaya-ocenka-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-sistemnaya-integraciya-js": () => import("./../../../src/pages/services/sistemnaya_integraciya.js" /* webpackChunkName: "component---src-pages-services-sistemnaya-integraciya-js" */),
  "component---src-pages-services-tehnicheskaya-ekspluataciya-js": () => import("./../../../src/pages/services/tehnicheskaya_ekspluataciya.js" /* webpackChunkName: "component---src-pages-services-tehnicheskaya-ekspluataciya-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */)
}

